import axios from "axios"
import { M_FEEDBACK, M_JOIN_US, M_SUBSCRIPTION } from "lib/query/mutation"
import {
  Q_ABOUT_US_PAGE,
  Q_BROWSE_BY_TOPICS,
  Q_CONTACT_US_PAGE,
  Q_DETAIL_NEWS,
  Q_FALLBACK_MORE_TO_READ,
  Q_GLOSSARY_DETAIL,
  Q_GLOSSARY_LIST,
  Q_HEADER_FOOTER,
  Q_HOME_DAILY_INSIGHT,
  Q_HOME_PAGE,
  Q_INVESTORS_DETAIL,
  Q_INVESTORS_LIST,
  Q_INVESTORS_PAGE,
  Q_LATEST_NEWS,
  Q_NEWS_ANALYSIS,
  Q_NEWS_DETAIL,
  Q_NEWS_LIST,
  Q_NEWS_TOPIC_DETAIL,
  Q_PAGE,
  Q_REFERRAL,
  Q_SEARCH_ALL,
  Q_SOCMED,
  Q_TERM_OF_THE_DAY_DETAIL,
  Q_TOP_SEARCH,
} from "lib/query/query"

const API_URL = process.env.NEXT_PUBLIC_BACKEND_URL

async function fetchAPI(query, variables = {}) {
  try {
    const headers = { "Content-Type": "application/json" }

    const res = await fetch(API_URL, {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables: { ...variables },
      }),
    })

    const json = await res.json()
    if (json.errors) {
      console.error(json.errors)
      throw new Error("Failed to fetch API")
    }
    return json.data
  } catch (error) {
    console.error(error)
    throw new Error(`GRAPHQL QUERY ${query}`)
    // return false
  }
}

async function axiosAPI(query, variables = {}) {
  try {
    const res = await axios.post(
      API_URL,
      JSON.stringify({
        query,
        variables: { ...variables },
      }),
      {
        maxBodyLength: Infinity,
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
      }
    )
    return res.data
  } catch (error) {
    console.error(error)
    throw new Error(`AXIOS ERROR ${query}`)
    // return false
  }
}

export async function fetchAPINext(type, variable) {
  try {
    const res = async () => await fetch('/api/mutation', {
      method: 'post',
      body: JSON.stringify({
        variable: { ...variable },
        type
      })
    });

    return (await res()).json()
  } catch (error) {
    console.log(error)
    throw new Error(`GRAPHQL MUTATION ${type}`)
  }
}

export async function getPrivacyPolicy() {
  const data = await fetchAPI(Q_PAGE, { id: "privacy-policy" })

  return data
}

export async function getTermCondition() {
  const data = await fetchAPI(Q_PAGE, { id: "term-conditions" })

  return data
}

export async function getHeaderFooter() {
  const data = await fetchAPI(Q_HEADER_FOOTER)

  return data
}

export async function getHome() {
  const data = await fetchAPI(Q_HOME_PAGE)
  const dataBrowseByTopics = await fetchAPI(Q_BROWSE_BY_TOPICS)
  const dataLatestNews = await fetchAPI(Q_LATEST_NEWS)
  const dataDailyInsight = await fetchAPI(Q_HOME_DAILY_INSIGHT)
  const dataInvestor101 = await fetchAPI(Q_INVESTORS_LIST)

  return {
    data: data?.page?.Homepage,
    seo: data?.page?.seo,
    dataBrowseByTopics: dataBrowseByTopics?.topics?.nodes,
    dataLatestNews: dataLatestNews?.newses?.nodes,
    dataDailyInsight: dataDailyInsight?.newsStories?.nodes,
    dataInvestor101: dataInvestor101?.investors101?.nodes,
  }
}

export async function getNewsAnalysis(first = 20) {
  const data = await fetchAPI(Q_NEWS_ANALYSIS)
  const dataLatestNews = await fetchAPI(Q_LATEST_NEWS, { first })
  const dataTopics = await fetchAPI(Q_BROWSE_BY_TOPICS)

  return {
    data,
    dataLatestNews: dataLatestNews?.newses?.nodes,
    dataTopics: dataTopics?.topics?.nodes,
  }
}

export async function getAbout() {
  const data = await fetchAPI(Q_ABOUT_US_PAGE)

  return data
}

export async function getContact() {
  const data = await fetchAPI(Q_CONTACT_US_PAGE)
  const dataReferral = await fetchAPI(Q_REFERRAL)

  return { data, dataReferral }
}

export async function getInvestor() {
  const data = await fetchAPI(Q_INVESTORS_PAGE)

  return data
}

export async function getInvestorList() {
  const data = await fetchAPI(Q_INVESTORS_LIST)

  return data?.investors101?.nodes
}

export async function getInvestorDetail(id) {
  const data = await fetchAPI(Q_INVESTORS_DETAIL, { id })

  return data?.investor101
}

export async function getGlossaryList(search) {
  const data = await fetchAPI(Q_GLOSSARY_LIST, { search })

  return data
}

export async function getGlossaryDetail(id) {
  const data = await fetchAPI(Q_GLOSSARY_DETAIL, { id })

  return data?.glossary
}

export async function getNewsList(search) {
  const data = await fetchAPI(Q_NEWS_LIST, { search })

  return data
}

export async function getNewsDetail(id) {
  const data = await fetchAPI(Q_NEWS_DETAIL, { id })

  return data?.news
}

export async function submitJoinUs(phoneNumber) {
  const data = await axiosAPI(M_JOIN_US, { phoneNumber })

  return data
}

export async function submitFeedback(feedback) {
  const data = await fetchAPI(M_FEEDBACK, { feedback })

  return data
}

export async function submitSubscription(variable) {
  const data = await fetchAPI(M_SUBSCRIPTION, { ...variable })

  return data
}

export async function getNewsTopic(slug) {
  const data = await fetchAPI(Q_NEWS_TOPIC_DETAIL, { slug })
  const dataTopics = await fetchAPI(Q_BROWSE_BY_TOPICS)
  return { data: data?.topics?.nodes, dataTopics: dataTopics?.topics?.nodes }
}

export async function getDetailNews(name) {
  const data = await fetchAPI(Q_DETAIL_NEWS, { name })
  return { data: data?.newses?.nodes }
}

export async function getFallbackMoretoread(slug) {
  const data = await fetchAPI(Q_FALLBACK_MORE_TO_READ, { slug })
  return { data: data?.topics?.nodes[0]?.newses?.nodes }
}

export async function getAllSearch(type) {
  const data = await fetchAPI(Q_SEARCH_ALL, { search: type })
  const topSearch = await fetchAPI(Q_TOP_SEARCH);
  return { data, topSearch }
}