import "assets/scss/main.scss"
import "swiper/css"
import "swiper/css/effect-coverflow"
import "swiper/css/autoplay"
import "nprogress/nprogress.css"
import { LoadingProvider } from "context/loading-context"
import { getHeaderFooter, getSocmed } from "lib/api/api"
import { WrapperProvider } from "context/wrapper-context"
import App from "next/app"
import NProgress from "nprogress"
import { useEffect } from "react"
import "react-toastify/dist/ReactToastify.css"
import { Slide, ToastContainer } from "react-toastify"
import { useWindowSize } from "components/hooks/hooks"
import TagManager from "react-gtm-module"

function MyApp({ Component, pageProps, headerFooter, router, topics }) {
  const { width } = useWindowSize()

  useEffect(() => {
    const handleRouteStart = () => NProgress.start()
    const handleRouteDone = () => NProgress.done()

    router.events.on("routeChangeStart", handleRouteStart)
    router.events.on("routeChangeComplete", handleRouteDone)
    router.events.on("routeChangeError", handleRouteDone)

    return () => {
      // Make sure to remove the event handler on unmount!
      router.events.off("routeChangeStart", handleRouteStart)
      router.events.off("routeChangeComplete", handleRouteDone)
      router.events.off("routeChangeError", handleRouteDone)
    }
  }, [])

  useEffect(() => {
    if (TagManager.initialize) {
      TagManager.initialize({
        gtmId: process.env.GOOGLE_TAG_MANAGER,
      })
    }
  }, [])

  useEffect(() => {
    const listSection = document.querySelectorAll("main > section")

    listSection?.forEach((item) => {
      item.classList.add("section-style")
    })

    document.documentElement.style.setProperty(
      '--window-inner-height',
      `${window.innerHeight}px`
    );
  }, [])

  return (
    <WrapperProvider data={headerFooter}>
      <LoadingProvider>
        <Component {...pageProps} />
        <ToastContainer
          position={width < 768 ? "top-center" : "top-right"}
          autoClose={3000}
          // progressStyle={{display:'none'}}
          progressClassName="progress-toast-custom"
          newestOnTop={false}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
          theme="colored"
          transition={Slide}
          icon={false}
          closeButton={false}
        />
      </LoadingProvider>
    </WrapperProvider >
  )
}

MyApp.getInitialProps = async (ctx) => {
  const appProps = await App.getInitialProps(ctx)

  const res = await getHeaderFooter()

  return { ...appProps, headerFooter: { ...res?.page, ...res?.topics } ?? "" }
}

export default MyApp
