export const Q_HOME_PAGE = `
  query Q_HOME_PAGE {    
      page(id: "homepage", idType: URI) {
        seo {
          title
          metaDesc
        }
        Homepage {
          termOfTheDay {
            ... on Glossary {
              id
              title
              Glossary {
                glossary {
                  term
                  text
                  image {
                    mediaItemUrl
                  }
                }
              }
            }
          }          
          todaysPick {
            news {
              ... on News {
                id
                slug
                newsId
                topics {
                  nodes {
                    name
                    slug
                  }
                }
              }
            }            
            button {
              url
              text
            }
            title
            image {
              mediaItemUrl
            }
          }
          partner {
            name
            url {
              appStore
              googlePlaystore
            }
            image {
              mediaItemUrl
            }
            benefit
          }
        }
      }    
  }
`

export const Q_PAGE = `
  query Q_PAGE($id: ID!) {
    page(id: $id, idType: URI) {
      seo {
        title
        metaDesc
      }
      uri
      title
      content
    }
  }
`

export const Q_HEADER_FOOTER = `
  query Q_HEADER_FOOTER {
    page(id: "header-footer", idType: URI) {
      headerFooter {
        address {
          text
        }
        button {
          text
          url
        }
        social {
          email
          instagramUrl
          telegramUrl
          tiktokUrl
          twitterUrl
          whatsappUrl
          youtubeUrl
        }
      }
    }
    topics {
      nodes {
        slug
        name
      }
    }
  }
`

export const Q_ABOUT_US_PAGE = `
  query Q_ABOUT_US_PAGE {
    page(id: "about-us", idType: URI) {
      seo {
        title
        metaDesc
      }
      uri
      title
      AboutUs {
        aboutCak {
          text
          image {
            mediaItemUrl
          }
        }
        meetTheCrew {
          image {
            mediaItemUrl
          }
          name
          position
          socialMedia {
            instagram
            linkedin
          }
        }
      }
    }
  }
`

export const Q_CONTACT_US_PAGE = `
  query Q_CONTACT_US_PAGE {
    page(id: "contact-us-join-us", idType: URI) {
      seo {
        title
        metaDesc
      }
      uri
      title
      ContactUs {
        giveFeedback {
          description
          title
        }
        joinUs {
          benefits {
            description
            icon {
              mediaItemUrl
            }
          }
          subtitle
          title
        }
      }
    }
  }
`

export const Q_INVESTORS_PAGE = `
  query Q_INVESTORS_PAGE {
    page(id: "investment", idType: URI) {
      seo {
        title
        metaDesc
      }
      uri
      title
      Investors101Page {
        header {
          subtitle
          title
        }
        polishYourSkill {
          ... on News {
            uri
            title
            newsId
            slug
            news {
              news {
                duration
                exclusivePost
                image {
                  mediaItemUrl
                }
                source {
                  pdf {
                    mediaItemUrl
                  }
                }
                text
                thumbnailImage {
                  mediaItemUrl
                }
                title
                youtubeEmbed
              }
            }
            topics {
              nodes {
                slug
              }
            }
          }
        }
      }
    }
  }
`

export const Q_INVESTORS_LIST = `
  query Q_INVESTORS_PAGE {
    investors101 {
      nodes {
        title
        slug
        uri
        Investors101 {
          article {            
            exclusivePost
            text
            thumbnailImage {
              mediaItemUrl
            }
            title
            video {
              mediaItemUrl
            }
            youtubeEmbed
          }
        }
      }
    }
  }
`

export const Q_INVESTORS_DETAIL = `
  query Q_INVESTORS_PAGE($id: ID!) {
    investor101(id: $id, idType: SLUG) {
      seo {
        title
        metaDesc
      }
      uri
      title
      Investors101 {
        article {
          exclusivePost
          text
          thumbnailImage {
            mediaItemUrl
          }
          title
          video {
            mediaItemUrl
          }
          youtubeEmbed
        }
      }
    }
  }
`

export const Q_GLOSSARY_LIST = `
  query Q_GLOSSARY_LIST($search: String) {
    glossaries(first: 10000, where: {search: $search, orderby: {field: TITLE, order: ASC}}) {
      nodes {
        seo {
          title
          metaDesc
        }
        uri
        title
        Glossary {
          glossary {
            image {
              mediaItemUrl
            }
            term
            termOfTheDay
            text
          }
        }
      }
    }
    topSearch: glossaries(first: 3) {
      nodes {
        uri
        title
        Glossary {
          glossary {
            image {
              mediaItemUrl
            }
            term
            termOfTheDay
            text
          }
        }
      }
    }
  }
`

export const Q_GLOSSARY_DETAIL = `
  query Q_GLOSSARY_DETAIL($id: ID!) {
    glossary(id: $id, idType: SLUG) {
      seo {
        title
        metaDesc
      }
      uri
      title
      Glossary {
        glossary {
          image {
            mediaItemUrl
          }
          term
          termOfTheDay
          text
        }
      }
    }
  }
`

export const Q_NEWS_LIST = `
  query Q_NEWS_LIST($search: String) {
    newses(first: 10000, where: {search: $search}) {
      nodes {
        uri
        title
        slug
        topics {
          nodes {
            name
            slug
          }
        }
        news {
          news {
            duration
            exclusivePost
            image {
              mediaItemUrl
            }
            source {
              pdf {
                mediaItemUrl
              }
            }
            text
            thumbnailImage {
              mediaItemUrl
            }
            title
            youtubeEmbed
          }
        }
      }
    }
    topSearch: newses(first: 5) {
      nodes {
        uri
        title
        news {
          news {
            duration
            exclusivePost
            image {
              mediaItemUrl
            }
            source {
              pdf {
                mediaItemUrl
              }
            }
            text
            thumbnailImage {
              mediaItemUrl
            }
            title
            youtubeEmbed
          }
        }
      }
    }
  }
`

export const Q_NEWS_DETAIL = `
  query Q_NEWS_DETAIL($id: ID!) {
    news(id: $id, idType: URI) {
      uri
      title
      news {
        news {
          duration
          exclusivePost
          image {
            mediaItemUrl
          }
          source {
            pdf {
              mediaItemUrl
            }
          }
          text
          thumbnailImage {
            mediaItemUrl
          }
          title
          youtubeEmbed
        }
        moreToRead {
          ... on News {
            id
            title
            slug
            uri
            news {
              news {
                image {
                  mediaItemUrl
                }
                text
                title
              }
            }
          }
        }
      }
    }
  }
`

export const Q_NEWS_ANALYSIS = `
  query Q_NEWS_ANALYSIS {
    page(id: "news-analysis-landing-page", idType: URI) {
      seo {
        title
        metaDesc
      }
      id
      newsAnalysis_landing {
        highlightNews {
          ... on News {
            id
            slug
            newsId
            topics {
              nodes {
                slug
                name
              }
            }
            news {
              news {
                image {
                  mediaItemUrl
                }
                title
                thumbnailImage {
                  mediaItemUrl
                }
              }
            }
            link
          }
        }
        discover {
          ... on NewsStory {
            id
            title
            link
            NewsStoryDetail {
              discover {
                thumbnail {
                  mediaItemUrl
                }
                media {
                  image {
                    mediaItemUrl
                  }
                  video {
                    mediaItemUrl
                  }
                }
              }
            }            
            slug
          }
        }
        trendingNow {
          ... on News {
            id
            newsId
            slug
            news {
              news {
                image {
                  mediaItemUrl
                }
                title                
              }
            }
            date
            topics {
              nodes {
                name
                slug
              }
            }
          }
        }
        quickTake {
          ... on News {
            id
            title
            slug
            topics {
              nodes {
                name
                slug
              }
            }
            news {
              news {
                duration
                image {
                  mediaItemUrl
                }
              }
            }
            newsId
            date
          }
        }
      }
    }
  }
`

export const Q_BROWSE_BY_TOPICS = `
  query Q_BROWSE_BY_TOPICS {
    topics {
      nodes {
        name
        link
        slug
      }      
    }
  }
`

export const Q_LATEST_NEWS = `
query Q_LATEST_NEWS($first: Int) {
  newses(first: $first) {
    nodes {
      newsId
      news {
        news {
          image {
            mediaItemUrl
          }
        }
      }
      title
      date
      slug
      topics {
        nodes {
          name
          slug
        }
      }
      link
    }
  }
}
`

export const Q_HOME_DAILY_INSIGHT = `
  query Q_HOME_DAILY_INSIGHT {
    newsStories {
      nodes {
        title
        uri
        NewsStoryDetail {
          dailyInsights {
            thumbnail {
              mediaItemUrl
            }
            media {
              image {
                mediaItemUrl
              }
              video {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
  }
`

export const Q_NEWS_TOPIC_DETAIL = `
query NewQuery($slug: [String]) {
  topics(where: {slug: $slug}) {
    nodes {
      seo {
        title
        metaDesc
      }
      name
      newses {
        nodes {
          news {
            news {
              image {
                mediaItemUrl
              }
            }
          }
          title
          slug
          newsId
          date  
          
          topics {
            nodes {
              name
              slug
            }
          }
        }
      }
      topic {
        pickAnalysis {
          change
          entryPrice
          fieldGroupName
          stockName
          stopLoss
          takeProfit
          button {
            text
            url
          }
        }
        ourTakeaways {
          ... on News {
            id
            newsId
            topics {
              nodes {
                name
                slug
              }
            }
            date
            title
            slug
            news {
              news {
                image {
                  mediaItemUrl
                }
              }
            }
          }
        }
        topNews {
          ... on News {
            id
            newsId
              topics {
              nodes {
                name
                slug
              }
            }
            title
            slug
            date
            news {
              news {
                image {
                  mediaItemUrl
                }
              }
            }
          }
        }
      }
    }
  }
}
`

export const Q_DETAIL_NEWS = `
  query Q_DETAIL_NEWS($name: String) {
    newses(where: {name: $name}) {
      nodes {
        seo {
          title
          metaDesc
        }
        slug
        news {
          moreToRead {
            ... on News {
              id
              title
              slug
              date
              news {
                news {
                  image {
                    mediaItemUrl
                  }
                }
              }
              newsId
            }
          }

          news {
            exclusivePost            
            image {
              mediaItemUrl
              description(format: RENDERED)
            }
            title
            text
            source {
              pdf {
                mediaItemUrl
                fileSize
                title
              }
            }
          }
        }
        date
        topics {
          nodes {
            name
            slug
          }
        }
      }
    }
  }
`

export const Q_FALLBACK_MORE_TO_READ = `
  query Q_FALLBACK_MORE_TO_READ($slug: [String]) {
    topics(where: {slug: $slug}) {
      nodes {
        newses {
          nodes {
            title
            slug
            date
            news {
              news {
                image {
                  mediaItemUrl
                }
              }                
            }            
          }
        }
      }
    }
  }
`

export const Q_SEARCH_ALL = `
  query Q_SEARCH_ALL($search: String) {
    investors101(where: {search: $search}) {
      nodes {
        Investors101 {
          article {
            text
            title
          }
        }
        slug
      }
    }
    glossaries(where: {search: $search}) {
      nodes {
        Glossary {
          glossary {
            text
          }
        }
        title
        slug
      }
    }
    newses(where: {search: $search}) {
      nodes {
        news {
          news {
            title
            text
          }
        }
        slug
      }
    }
    newsStories(where: {search: $search}) {
      nodes {
        NewsStoryDetail {
          dailyInsights {
            thumbnail {
              mediaItemUrl
            }
          }
          discover {
            thumbnail {
              mediaItemUrl
            }
          }
        }
        title
      }
    }    
  }
`

export const Q_TOP_SEARCH = `
  query Q_TOP_SEARCH {
    newses(first: 5) {
      nodes {
        uri
        title
        slug
        news {
          news {
            duration
            exclusivePost
            image {
              mediaItemUrl
            }
            source {
              pdf {
                mediaItemUrl
              }
            }
            text
            thumbnailImage {
              mediaItemUrl
            }
            title
            youtubeEmbed
          }
        }
      }
    }
  }
`

export const Q_REFERRAL = `
query Q_HOME_PAGE {    
  page(id: "homepage", idType: URI) {    
    Homepage {      
      partner {
        name
        url {
          appStore
          googlePlaystore
        }
        image {
          mediaItemUrl
        }
        benefit
      }
    }
  }    
}
`