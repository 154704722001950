import React, { createContext, useState } from "react"

export const WrapperContext = createContext()

export const WrapperProvider = ({ children, data }) => {
  const [headerFooter, setHeaderFooter] = useState(data)
  return (
    <WrapperContext.Provider value={{ headerFooter, setHeaderFooter }}>
      {children}
    </WrapperContext.Provider>
  )
}
